<template>
  <div>
    <div
      style="
        width: 100%;
        height: 100%;
        position: absolute;
        background: white;
        z-index: 1000;
      "
      v-if="isLoader"
    >
      <CircleLoader />
    </div>
    <div v-if="isLoader == false">
      <div id="snackbar"></div>
      <b-modal
        id="resendEmailModal"
        ref="resendEmailModal"
        :title="'Resend Mail'"
        size="lg"
        no-close-on-esc
        no-close-on-backdrop
        hide-footer
      >
        <div class="row">
          <b-col sm="12 row">
            <b-col sm="3" class="text-right">
              <label for="from-date"
                >Email Id :<b class="text-danger">*</b></label
              >
            </b-col>
            <b-col sm="9">
              <b-form-input
                type="text"
                v-model="resendEmail.UserEmail"
                placeholder="Enter Email Id"
              ></b-form-input>
            </b-col>
          </b-col>
        </div>
        <hr />
        <b-button
          @click="hideresendEmailModal"
          variant="danger"
          class="pull-right ml-2 mt-2"
        >
          Close</b-button
        >
        <b-button @click="Resend" variant="success" class="pull-right mt-2">
          Resend</b-button
        >
      </b-modal>

      <h4><strong>Filter</strong></h4>
      <hr />
      <b-row>
        <b-col sm="3">
          <b-form-group>
            <label for="Title">Select Country</label>
            <b-form-select
              v-model="country_id"
              id="em_name"
              :plain="true"
              text-field="CountryName"
              value-field="CountryID"
              :options="country_options"
              @input="loadReports"
            >
              <template slot="first">
                <option :value="null">Select Country</option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          <b-form-group>
            <label for="Title">From Date</label>
            <b-form-input
              type="date"
              required
              v-model="event_start_date"
              id="Title"
              placeholder="Name"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group>
            <label for="Title">To Date</label>
            <b-form-input
              type="date"
              required
              v-model="event_end_date"
              id="Title"
              placeholder="Name"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group>
            <label for="Title">Event Name</label>
            <b-form-select
              class="form-control"
              label="booking_type"
              text-field="e_name"
              value-field="e_name"
              v-model="event_name"
              :options="event_name_options"
              placeholder=""
            >
              <template slot="first">
                <option :value="''" disabled>Select Event</option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group>
            <label for="Title">Ticket Type</label>
            <b-form-select
              class="form-control"
              label="booking_type"
              v-model="ticket_type"
              :options="ticket_type_options"
              @input="loadReports"
            />
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group>
            <label for="Title">Status</label>
            <b-form-select
              class="form-control"
              label="booking_type"
              v-model="event_status"
              :options="event_status_options"
              @input="loadReports"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <b-button @click="loadReports">Search</b-button>
        <b-button class="ml-5" @click="resetData">Reset</b-button>
      </b-row>
      <hr />
      <div class="d-flex justify-content-between">
        <h4><strong>Reports Details</strong></h4>
        <div>
          <h6>
            <strong>No. of Tickets: {{ totalTicket }} </strong>
          </h6>
          <h6>
            <strong
              >Total Amount: {{ totalTicketAmount }}
              <span v-if="country_id == null || country_id == 113">BHD</span>
              <span v-if="country_id == 175">QAR</span>
              <span v-if="country_id == 112">AED</span>
            </strong>
          </h6>
        </div>
      </div>
      <hr />
      <div class="table-reports">
        <v-client-table
          class="my-2"
          :data="data2"
          :columns="columns2"
          :options="options2"
        >
          <div slot="status" slot-scope="props">
            <span v-if="props.row.status == 1">Active</span>
            <span v-if="props.row.status == 2 || props.row.status == 3"
              >Inactive</span
            >
          </div>

          <div slot="sr_no" slot-scope="props">
            <span>
              {{
                props.index +
                (isNaN(pagination.pageSize)
                  ? 0
                  : (currentPage - 1) * pagination.pageSize)
              }}</span
            >
          </div>
          <div slot="createdAt" slot-scope="props">
            <span v-if="props.row.createdAt != null">{{
              moment(props.row.createdAt).format("YYYY-MM-DD")
            }}</span>
          </div>

          <div slot="purchase_mode" slot-scope="props">
            <span
              >{{
                props.row.purchase_mode === "1"
                  ? "Credit Card"
                  : props.row.purchase_mode === "2"
                  ? "Debit Card"
                  : props.row.purchase_mode
              }}
            </span>
          </div>

          <div slot="ev_start" slot-scope="props">
            <span>{{ moment(props.row.ev_start).format("YYYY-MM-DD") }}</span>
          </div>

          <div slot="ev_end" slot-scope="props">
            <span>{{ moment(props.row.ev_end).format("YYYY-MM-DD") }}</span>
          </div>

          <div slot="Prefix" slot-scope="props">
            <b-button
              v-if="event_status == 'success'"
              @click="resendEmailModal(props.row)"
              size="sm"
              variant="primary"
              class="ml-1 mr-1 mt-1 mb-1"
              >Resend</b-button
            >
            <span v-if="event_status != 'success'">None</span>
          </div>
        </v-client-table>

        <b-pagination
          limit="10"
          v-model="currentPage"
          :total-rows="pagination.total"
          :per-page="pagination.pageSize"
        ></b-pagination>
      </div>
      <b-row class="d-flex justify-content-center mb-4 m-lg">
        <a
          class="btn"
          :href="
            this.baseUrl +
            '/v1/reports/excel?pg_status=' +
            this.event_status +
            '&event_name=' +
            this.event_name +
            '&ticket_type=' +
            this.ticket_type +
            '&event_start_date=' +
            this.event_start_date +
            '&event_end_date=' +
            this.event_end_date +
            '&token=' +
            this.token
          "
          >Export Excel</a
        >
        <b-button
          class="ml-2"
          type="submit"
          size="sm"
          variant="primary"
          @click="generatePdf"
          >Export PDF</b-button
        >
      </b-row>
    </div>
  </div>
</template>

<script>
import MasterService from "@/services/MasterService";
import { BASEURLEVENTS } from "../../helper/constant.js";
import Vue from "vue";

import moment from "moment";
import XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import wanasaImage from "@/helper/wanasaImage";
import { pdfFillColor } from "@/helper/constant";
import CircleLoader from "../SchedulesDetails/Circle.vue";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: {
    CircleLoader,
    DateRangePicker,
  },
  data() {
    return {
      isLoader: false,

      currentPage: 1,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      finalArr: [],
      finalArr1: [],
      event_start_date: "",
      event_end_date: "",
      event_name: "",
      event_name_options: [],
      event_status: "success",
      ticket_type: "",

      ticket_type_options: [
        { value: "", text: "All" },
        { value: "1", text: "Regular" },
        { value: "2", text: "Coupon / Voucher" },
      ],
      event_status_options: [
        { value: "", text: "All" },
        { value: "success", text: "Success" },
        { value: "init", text: "Initial" },
        { value: "failed", text: "Failed" },
        { value: "cancel", text: "Cancelled" },
      ],
      filteredData: [],
      ReportsData: [],
      data2: [],
      params: "",
      resendEmail: {
        UserEmail: "",
        UserMobile: "",
        ReserveID: "",
      },

      columns2: [
        "sr_no",
        "event_booked_id",
        "order_id",
        "event_name",
        "pg_name",
        "ticket_category",
        "ticket_sub_cat",
        "status",
        "es_date",
        "es_time",
        "user_id",
        "user_name",
        "email",
        "mobile",
        "no_of_ticket",
        "seat_names",
        "total_price",
        "createdAt",
        "updatedAt",
        "purchase_mode",
        "ev_start",
        "ev_end",
        "Prefix",
      ],

      options2: {
        headings: {
          event_booked_id: "Booking ID",
          order_id: "Order ID",
          event_name: "Event Name",
          pg_name: "PG Name",
          ticket_category: "Ticket Category",
          ticket_sub_cat: "Ticket Sub Category",
          status: "Event Status",
          es_date: "Event Date",
          es_time: "Event Time",
          user_id: "User Id",
          user_name: "Customer Name",
          email: "Customer Email",
          mobile: "Customer Mobile",
          no_of_ticket: "Number of Tickets",
          seat_names: "Seat Names",
          total_price: "Total Price",
          createdAt: "Event Booked Date",
          updatedAt: "Booking Time Stamp",
          purchase_mode: "Purchase Mode",
          ev_start: "Event Start Date",
          ev_end: "Event End Date",
          Prefix: "Actions",
        },

        sortable: [],
        filterable: ["event_name"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        perPage: 10,
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll",
        },
        texts: {
          noResults: "No Bookings To Display",
        },
      },
      totalTicket: "",
      totalTicketAmount: "",
      country_id: null,
      country_options: [],
      baseUrl: null,
      token: null,
    };
  },

  beforeMount() {
    this.getCountry();
  },

  mounted() {
    this.loadReports();
    this.getAllEventsDropdown();

    this.baseUrl = BASEURLEVENTS;
    this.token = Vue.cookie.get("TICHTID");
  },

  computed: {
    rows() {
      return this.filteredData.length;
    },
  },

  watch: {
    currentPage: {
      handler: function (value) {
        this.params = `/page=${value}&size=${this.pagination.pageSize}`;
        this.loadReports();
      },
    },
  },

  methods: {
    generatePdf() {
      this.isLoader = true;
      window.location.href =
        this.baseUrl +
        `/v1/reports/pdf?pg_status=` +
        this.event_status +
        `&event_name=` +
        this.event_name +
        `&event_start_date=` +
        this.event_start_date +
        `&event_end_date=` +
        this.event_end_date +
        `&token=` +
        this.token;
    },
    getAllEventsDropdown() {
      MasterService.getAllPageEvent().then((response) => {
        console.log("responseevnts", response.data);
        if (response.error) {
          this.showMessageSnackbar(response.error);
        } else {
          this.event_name_options = this.uniqueArrayOfObject(
            response.data.data,
            "e_name"
          );
          console.log("eventList", this.event_name_options);
        }
      });
    },

    loadReports() {
      this.params = `?page=${this.currentPage}`;
      this.isLoader = true;
      console.log(this.params, "params");
      let finalArr = this.finalArr;
      let payload = {
        country_id: this.country_id,
        event_name: this.event_name,
        pg_status: this.event_status,
        ticket_type: this.ticket_type,
        event_start_date: this.event_start_date,
        event_end_date: this.event_end_date,
      };

      MasterService.getAllEventsReportList(this.params, payload)
        .then((response) => {
          this.data2 = response.data.data;
          this.totalTicket = 0;
          this.totalTicketAmount = 0;

          this.pagination.total = response.data.pagination.total;
          this.currentPage = response.data.pagination.current;
          this.pagination.pageSize = response.data.pagination.pageSize;

          this.ReportsData = response.data.data;
          let filterNewData = this.ReportsData.map((el, index) => {
            finalArr.push({
              "Booking Id": el["event_booked_id"],
              "Order Id": el["order_id"],
              "Event Name": el["event_name"],
              "PG Name": el["pg_name"],
              "Ticket Category": el["ticket_category"],
              "Ticket Sub Category": el["ticket_sub_cat"],
              "Event Status": el["status"] == "1" ? "Active" : "In Active",
              "Event Date": el["es_date"],
              "Event Time": el["es_time"],
              "User Id": el["user_id"],
              "Customer Name": el["user_name"],
              "Customer Email": el["email"],
              "Customer Mobile": el["mobile"],
              "Number of Tickets": el["no_of_ticket"],
              "Total Price": el["total_price"],
              "Event Booked Date": el["createdAt"]
                ? moment(el["createdAt"]).format("YYYY-MM-DD")
                : "",
              "Booking Time Stamp": el["createdAt"],
              "Purchase Mode":
                el["purchase_mode"] === "1"
                  ? "Credit Card"
                  : el["purchase_mode"] === "2"
                  ? "Debit Card"
                  : el["purchase_mode"],
              "Event Start Date": el["ev_start"]
                ? moment(el["ev_start"]).format("YYYY-MM-DD")
                : "",
              "Event End Date": el["ev_end"]
                ? moment(el["ev_end"]).format("YYYY-MM-DD")
                : "",
            });

            this.filteredData = this.uniqueArrayOfObject(
              finalArr,
              "Booking Id"
            );
            this.totalTicket = response.data.total_seat;
            this.totalTicketAmount = response.data.total_price.toFixed(2);
          });
          this.isLoader = false;
        })
        .catch((error) => {
          console.log("Catch on Getting Event Error: ", error);
        });
    },

    sendEmail(item) {
      console.log(item, "dataaa");
    },

    moment: function (date) {
      return moment(date);
    },

    resetData() {
      this.filteredData = this.finalArr;
      this.finalArr1 = [];
      (this.country_id = null), (this.event_start_date = "");
      this.event_end_date = "";
      this.event_name = "";
      this.event_status = "success";
      this.loadReports();
    },

    resendEmailModal(items) {
      console.log("items", items);
      this.resendEmail.UserEmail = items.email;
      this.resendEmail.ReserveID = items.event_reserve_id;
      this.$refs["resendEmailModal"].show();
    },

    hideresendEmailModal() {
      this.resendEmail.UserEmail = "";
      this.resendEmail.UserMobile = "";
      this.resendEmail.ReserveID = "";
      this.$refs["resendEmailModal"].hide();
    },

    getCountry() {
      MasterService.getCountryEvent()
        .then((response) => {
          console.log(response.data.List, "countryyyyy");
          response.data.List.map((item) => {
            item.value = item.CountryID;
            item.text = item.CountryName;
            if (item.CountryName === "UAE") {
              item.disabled = true;
            }
            this.country_options.push(item);
          });
        })
        .catch((error) => {
          console.log("Catch on Getting Country Event Error: ", error);
        });
    },

    Resend() {
      if (
        this.resendEmail.ReserveID != "" &&
        this.resendEmail.UserEmail != ""
      ) {
        let payload = {
          testemail: this.resendEmail.UserEmail,
          event_reserve_id: this.resendEmail.ReserveID,
        };

        MasterService.resendReportsEmail(payload)
          .then((response) => {
            const { data } = response;
            this.hideresendEmailModal();
            this.showMessageSnackbar(
              data.message
                ? data.message
                : data.error
                ? data.error.message
                  ? data.error.message
                  : data.error
                : data
            );
          })
          .catch((error) => {
            console.log("Catch on send email Error: ", error);
            this.showMessageSnackbar(
              "Oops! Something went wrong. kindly contact your administrator"
            );
          });
      } else {
        this.showMessageSnackbar("Enter Email");
      }
    },

    uniqueArrayOfObject: function (array, keyToBeUnique) {
      return Object.values(
        array.reduce((tmp, x) => {
          // You already get a value
          if (tmp[x[keyToBeUnique]]) return tmp;

          // You never envcountered this key
          tmp[x[keyToBeUnique]] = x;

          return tmp;
        }, {})
      );
    },
    showMessageSnackbar: function (message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
  },
};
</script>

<style>
.table-reports .VueTables__search-field {
  display: flex !important;
}
.table-reports .VueTables__search__input {
  margin-left: 8px;
}
.VueTables__no-results td {
  text-align: left !important;
  padding-left: 50px;
}
</style>
